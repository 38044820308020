<template lang="pug">
.dashboard-container
  .sidebar-menu-container.bg-white
    b-img(src="@/assets/logo.png", fluid, center, style="max-width: 100px;margin-bottom: 2rem;")
    .my-4.text-right(v-if="!isPushEnabled")
      b-button.text-sm(variant="success", @click="requestPushPermissions")
        bell-icon.icon-sm
        span.ml-2 Activar notificaciones
    b-nav
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Usuarios'}") Usuarios
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Clientes'}") Clientes
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Perfiles'}") Perfiles
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Devices'}") Dispositivos
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Actividad'}") Actividad
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'QRs'}") QRs
      b-nav-item.w-100(:link-classes="['sidebar', 'nav-link-dark']", :to="{name: 'Reports'}") Reportes
      b-nav-item(:to="{name: 'Logout'}", style="margin-top:1rem;") Cerrar sesión
  .dashboard-router-container
    //- b-navbar(type="light")
    //-   b-navbar-nav.ml-auto
    //-     b-nav-item(:link-classes="['nav-link-dark']", :to="{name: 'Logout'}") Cerrar sesión
    div(style="padding:2rem;")
      router-view
</template>

<script>
export default {
  data () {
    return {
      isPushEnabled: true
    }
  },
  methods: {
    async checkPushStatus (isAfterRequest) {
      var pushEnabled = await OneSignal.isPushNotificationsEnabled();
      this.isPushEnabled = pushEnabled

      if (pushEnabled && isAfterRequest == true) {
        this.$bvToast.toast('Se activaron las notificaciones correctamente', {
          title: `Notificaciones activas`,
          variant: 'success'
        })
      }
    },
    async requestPushPermissions () {
      OneSignal.showSlidedownPrompt().then(res => {
        this.checkPushStatus(true)
      });
    }
  },
  mounted () {
    this.checkPushStatus()
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/theme.scss';
$menu-width: 200px;

.dashboard-container {
  position: relative;
  width: 100%;
  overflow-x: hidden;

  .sidebar-menu-container {
    width: $menu-width;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    padding: 2rem;
    overflow: hidden;
    overflow-y: auto;
  }

  .dashboard-router-container {
    width: 100%;
    padding-left: $menu-width;
  }
}

.nav-link-dark {
  color: black!important;
  width: 100%;
  border-radius: 5px;

  &.sidebar {
    flex: 1;
    margin-bottom: .5rem;
    border: 1px solid white;

    &.router-link-exact-active {
      border: 1px solid $primary!important;
      background-color: $primary;
      color: white!important;
    }

    &:hover {
      border: 1px solid $primary!important;
    }
  }
}
</style>